::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
::placeholder {
    color: #bec6cc;
    font-size: 12px;
}
.form-control::-webkit-input-placeholder {
     color: #bec6cc;
 }
.form-control:-moz-placeholder {
    color: #bec6cc;
}
.form-control::-moz-placeholder {
    color: #bec6cc;
}
.form-control::placeholder {
    color: #bec6cc;
}
.form-control:-ms-input-placeholder {
    color: #bec6cc;
}

label.is-invalid {
    color: red;
}

.chat-messages {
    max-height: 530px;
}

.showDiv { display: block; }
.hideDiv { display: none; }

.checkbox-lable{ width: 200px; }